.inputContainer {

    .input {
        border-radius: 5px;

        &.adornedEnd {
            padding-right: 0;
        }

        input {
            padding: 15px 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            height: 18px;
            min-height: 18px;

            &.inputAdornedEnd {
                padding-right: 0;
            }
        }

        textarea {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            height: 18px;
            min-height: 18px;

            &.inputAdornedEnd {
                padding-right: 0;
            }
        }


        input::placeholder,
        textarea::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px
        }



        .adorned {
            min-width: 48px;
            height: 48px;
            max-height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0;

            svg {
                color: #BAC2D0;
                fill: #BAC2D0;
                stroke: #BAC2D0;
            }
        }








    }

}