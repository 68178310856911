.drawer {
    width: 100%;
    max-width: 250px;
}

.draweImageContainer {
    padding: 30px 20px 50px 10px;

    img {
        width: 100%;
        height: auto;
        border-radius: 50%;
    }
}

.list {
    margin-bottom: auto !important;
}

.logout {
    margin: 20px !important;
}

.logoutIcon {
    margin-right: 10px;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 30px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
        margin: 0 auto;
        height: 100%;
        width: auto;
    }

    .name {
        margin-left: auto;
        margin-right: 10px;
    }

    .avatar {}
}

.children {
    flex-grow: 1;
    width: 100%;
    max-width: var(--content-max-width);
}