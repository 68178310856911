.content {
    padding-top: 30px;
    display: flex;
    flex-direction: column;

    .image {
        align-self: center;
        width: 200px;
        height: 200px;
        margin-bottom: 30px;
    }

    .textName {
        align-self: center;
        font-weight: bolder;
        font-size: 32px;
        margin-bottom: 10px;
    }

    .textEmail {
        align-self: center;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .config {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .select {
        width: 100px;
        margin-left: 10px;
    }
}