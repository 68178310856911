.fab {
    position: fixed !important;
    bottom: 40px;
    right: 40px;
}

.filter {
    margin-bottom: 20px;
}

.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.chartContainer {
    width: 244px;
    height: 244px;
}

.align {
    display: flex;
    align-items: center;
}

.textCard {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;

    .icon {
        width: 60px;
        height: 60px;
        margin-right: 20px;
    }
}