@import "styles/main.scss";

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100% !important;
}

.BM-datepicker.react-datepicker-popper {
  z-index: 3000;

  .react-datepicker {
    background: #fff !important;
    box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.16) !important;
    border: none !important;

    .react-datepicker__triangle {
      display: none !important;

      &::before {
        display: none !important;
      }
    }

    .react-datepicker__header {
      background-color: rgba($color: #1976d2, $alpha: 0.5) !important;
    }

    .react-datepicker__day-name {
      color: #1976d2 !important;
    }

    .react-datepicker__day,
    .react-datepicker__time-name {
      color: #828282 !important;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      color: #1976d2 !important;
      background-color: rgba($color: #1976d2, $alpha: 0.2) !important;
    }

    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      color: #828282 !important;
      background-color: transparent !important;
    }

    .react-datepicker__day--selected {
      color: #FFFFFF !important;
      background-color: #1976d2 !important;

      &:hover {
        color: #FFFFFF !important;
        background-color: #1976d2 !important;

      }
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      cursor: default;
      color: #BDBDBD !important;
      opacity: 0.5 !important;

      &:hover {
        color: #BDBDBD !important;
      }
    }

  }

}

:root {
  --content-max-width: 1080px
}

* {
  box-sizing: border-box;
  font-family: "Epilogue" !important;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
}

a,
a:-webkit-any-link {
  text-decoration: none;
}